import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Formulario extends Component {

    state = { 
            categorias : [
                         { id : 1, value: "general"       , text: "general"       }
                        ,{ id : 2, value: "businnes"      , text: "businnes"      }
                        ,{ id : 3, value: "entertainment" , text: "entertainment" }
                        ,{ id : 4, value: "health"        , text: "health"        }
                        ,{ id : 5, value: "science"       , text: "science"       }
                        ,{ id : 6, value: "sports"        , text: "sports"        }
                        ,{ id : 7, value: "technology"    , text: "technology"    }
            ], 
            categoria : 'general'  // alternativa 1 para asignar categoria inicial 
    }

    cambiarCategoria = e => {
        this.setState ({
            categoria: e.target.value
        }, () => { 

            // pasarlo a la pagina principal para consultar nuevamente la API con la categoria
            this.props.consultarNoticias(this.state.categoria); 
            
        })

    }

    render() {
        return (
            <div className="buscador row">
                <div className="col s12 m8 offset-m2">
                    <form action="">
                        <h2>Encuentra noticias por categoría</h2>
                        <div className="input-field col s12 m8 offset-m2">
                            <select 
                                name="s_categorias" 
                                id="s_categorias"
                                onChange={this.cambiarCategoria}
                                >   
                                    {this.state.categorias.map(categoria => (
                                        <option
                                            key={categoria.id}
                                            value={categoria.value}
                                        >
                                            {categoria.text}
                                        </option>
                                    ))}
                                    
                                    
                            </select>
                        </div>

                    </form>
                </div>
            </div>  
        );
    }
}

Formulario.propTypes = {
    consultarNoticias : PropTypes.func.isRequired
}
 
export default Formulario;