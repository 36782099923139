import React from 'react';

const Noticia = ({noticia}) => {

    // extraer los datos de la API 
    const {urlToImage, url, title, description, source  } = noticia;

    // considionalmente cargar imagen si está disponible 

    const imagen = (urlToImage) ? 
        <div className="card-image">
            <a  href={url}
                target="_blank"
                rel="noopener noreferrer"  
                >
                <img src={urlToImage} alt={title}/>
                <span className="card-title">{source.name}</span>
            </a>
        </div>
    : null;

    console.log(source);

    return (
        <div className="col s12 m6 l4">
            <div className="card">
                {imagen}
                <div className="card-content center">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <div className="card-action">
                    <a  href={url} 
                        target="_blank"
                        rel="noopener noreferrer"  
                        className="waves-effect waves-light btn">
                        ver noticia
                    </a>
                </div>




            </div>
        </div>
    )
}

export default Noticia;