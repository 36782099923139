import React, { Component, Fragment } from 'react';
import Header from './components/Header';
import ListaNoticias from './components/ListaNoticias';
import Formulario from './components/Formulario';

class App extends Component {
  state = { 
    noticias : []
   }

  //
  //  EVENTO CARGA
  //
  componentDidMount() {
    this.consultarNoticias();
  }
  
  //
  //  FUNCIONES 
  //

  consultarNoticias = async (categoria = 'general')  => {

    const url = `https://newsapi.org/v2/top-headlines?country=co&category=${categoria}&apiKey=06fcdb2bc37747c8bcfa0ea401991b42`;
    // https://newsapi.org/v2/everything?q=apple&from=2019-12-23&to=2019-12-23&sortBy=popularity&apiKey=06fcdb2bc37747c8bcfa0ea401991b42
    
    const respuesta = await fetch(url);
    const noticias = await respuesta.json();

    console.log(noticias.articles);

    this.setState ({
      noticias: noticias.articles
    })

  }
 
  //
  //  RENDERIZAR
  //
  render() {



    return (
      <Fragment>
          <Header 
            titulo='Noticias con API de React'
            /> 

            <div className="container white contenedor-noticias">
              <Formulario 
                  consultarNoticias = {this.consultarNoticias}
              />
              <ListaNoticias 
                noticias={this.state.noticias}
              />
            </div>
      </Fragment>
    );
  }
}

export default App;
